<template>
  <editor :type="'experiment'" :initialData="experiment" @save="save"></editor>
</template>
<script>
  import Editor from '../Experiments/Editor.vue'
  import swal from 'sweetalert2'

  export default {
    data() {
      return {
        experiment: null
      }
    },
    components: {
      Editor
    },
    created() {
      var self = this
      var xhr = new XMLHttpRequest()
      xhr.open('GET', this.$root.apiUrl + 'v1/experiments/' + self.$route.params.id)
      xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
      xhr.onload = function () {
        if (xhr.status === 401) self.$router.push({name: 'Login'})
        var response = JSON.parse(xhr.responseText)
        if (xhr.status === 200 && response.response) {
          if (!response.response.code) {
            response.response.code = '{"sections":[]}'
          }
          self.experiment = response.response
        } else if (xhr.status === 404) {
          self.$router.push({name: 'Experiments'})
        } else {
          var message
          if (response.response && response.response[0] && response.response[0].message)
            message = response.response[0].message
          else
            message = 'Unknown error'
          self.showSweetAlertError(message)
        }
      }
      xhr.send()
    },
    methods: {
      save (value) {
        this.experiment = value
        var self = this
        var formData = new FormData()
        formData.append('name', this.experiment.name)
        formData.append('slug', this.experiment.slug)
        formData.append('status', this.experiment.status)
        formData.append('code', JSON.stringify(this.experiment.code))
        var xhr = new XMLHttpRequest()
        xhr.open('POST', this.$root.apiUrl + 'v1/experiments/' + self.$route.params.id)
        xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
        xhr.onload = function () {
          if (xhr.status === 401) self.$router.push({name: 'Login'})
          var response = JSON.parse(xhr.responseText)
          if (xhr.status === 201) {
            self.notifyVue('top', 'right', 'Experiment saved succesfully.')
          } else {
            var message
            if (response.response && response.response[0] && response.response[0].message)
              message = response.response[0].message
            else
              message = 'Unknown error'
            self.showSweetAlertError(message)
          }
        }
        xhr.send(formData)
      },
      showSweetAlertError (message) {
        swal({
          title: 'An error occured!',
          text: message,
          type: 'warning',
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Try again',
          cancelButtonClass: 'md-button md-success',
          buttonsStyling: false
        })
      },
      notifyVue (verticalAlign, horizontalAlign, text) {
        this.$notify(
          {
            message: text,
            icon: 'save',
            horizontalAlign: horizontalAlign,
            verticalAlign: verticalAlign,
            type: 'success'
          }
        )
      }
    }
  }
</script>
<style>
</style>
